@font-face {
    font-family: "uicons-regular-straight";
    src: url("../../../../public/fonts/uicons/uicons-regular-straight.eot") format("embedded-opentype"),
    url("../../../../public/fonts/uicons/uicons-regular-straight.woff2") format("woff2"),
    url("../../../../public/fonts/uicons/uicons-regular-straight.woff") format("woff");
}

i[class^="fi-rs-"], span[class^="fi-rs-"] {
    line-height: 0 !important;
}

i[class^="fi-rs-"]:before, i[class*=" fi-rs-"]:before, span[class^="fi-rs-"]:before, span[class*="fi-rs-"]:before {
    font-family: uicons-regular-straight !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fi-rs-add:before {
    content: "\f101";
}
.fi-rs-alarm-clock:before {
    content: "\f102";
}
.fi-rs-align-center:before {
    content: "\f103";
}
.fi-rs-align-justify:before {
    content: "\f104";
}
.fi-rs-align-left:before {
    content: "\f105";
}
.fi-rs-align-right:before {
    content: "\f106";
}
.fi-rs-ambulance:before {
    content: "\f107";
}
.fi-rs-angle-double-left:before {
    content: "\f108";
}
.fi-rs-angle-double-right:before {
    content: "\f109";
}
.fi-rs-angle-double-small-left:before {
    content: "\f10a";
}
.fi-rs-angle-double-small-right:before {
    content: "\f10b";
}
.fi-rs-angle-down:before {
    content: "\f10c";
}
.fi-rs-angle-left:before {
    content: "\f10d";
}
.fi-rs-angle-right:before {
    content: "\f10e";
}
.fi-rs-angle-small-down:before {
    content: "\f10f";
}
.fi-rs-angle-small-left:before {
    content: "\f110";
}
.fi-rs-angle-small-right:before {
    content: "\f111";
}
.fi-rs-angle-small-up:before {
    content: "\f112";
}
.fi-rs-angle-up:before {
    content: "\f113";
}
.fi-rs-apps-add:before {
    content: "\f114";
}
.fi-rs-apps-delete:before {
    content: "\f115";
}
.fi-rs-apps-sort:before {
    content: "\f116";
}
.fi-rs-apps:before {
    content: "\f117";
}
.fi-rs-archive:before {
    content: "\f118";
}
.fi-rs-arrow-down:before {
    content: "\f119";
}
.fi-rs-arrow-left:before {
    content: "\f11a";
}
.fi-rs-arrow-right:before {
    content: "\f11b";
}
.fi-rs-arrow-small-down:before {
    content: "\f11c";
}
.fi-rs-arrow-small-left:before {
    content: "\f11d";
}
.fi-rs-arrow-small-right:before {
    content: "\f11e";
}
.fi-rs-arrow-small-up:before {
    content: "\f11f";
}
.fi-rs-arrow-up:before {
    content: "\f120";
}
.fi-rs-asterisk:before {
    content: "\f121";
}
.fi-rs-backpack:before {
    content: "\f122";
}
.fi-rs-badge:before {
    content: "\f123";
}
.fi-rs-ban:before {
    content: "\f124";
}
.fi-rs-band-aid:before {
    content: "\f125";
}
.fi-rs-bank:before {
    content: "\f126";
}
.fi-rs-barber-shop:before {
    content: "\f127";
}
.fi-rs-baseball:before {
    content: "\f128";
}
.fi-rs-basketball:before {
    content: "\f129";
}
.fi-rs-bell-ring:before {
    content: "\f12a";
}
.fi-rs-bell-school:before {
    content: "\f12b";
}
.fi-rs-bell:before {
    content: "\f12c";
}
.fi-rs-billiard:before {
    content: "\f12d";
}
.fi-rs-bold:before {
    content: "\f12e";
}
.fi-rs-book-alt:before {
    content: "\f12f";
}
.fi-rs-book:before {
    content: "\f130";
}
.fi-rs-bookmark:before {
    content: "\f131";
}
.fi-rs-bowling:before {
    content: "\f132";
}
.fi-rs-box-alt:before {
    content: "\f133";
}
.fi-rs-box:before {
    content: "\f134";
}
.fi-rs-briefcase:before {
    content: "\f135";
}
.fi-rs-broom:before {
    content: "\f136";
}
.fi-rs-browser:before {
    content: "\f137";
}
.fi-rs-brush:before {
    content: "\f138";
}
.fi-rs-bug:before {
    content: "\f139";
}
.fi-rs-building:before {
    content: "\f13a";
}
.fi-rs-bulb:before {
    content: "\f13b";
}
.fi-rs-calculator:before {
    content: "\f13c";
}
.fi-rs-calendar:before {
    content: "\f13d";
}
.fi-rs-camera:before {
    content: "\f13e";
}
.fi-rs-caret-down:before {
    content: "\f13f";
}
.fi-rs-caret-left:before {
    content: "\f140";
}
.fi-rs-caret-right:before {
    content: "\f141";
}
.fi-rs-caret-up:before {
    content: "\f142";
}
.fi-rs-check:before {
    content: "\f143";
}
.fi-rs-checkbox:before {
    content: "\f144";
}
.fi-rs-chess:before {
    content: "\f145";
}
.fi-rs-circle-small:before {
    content: "\f146";
}
.fi-rs-circle:before {
    content: "\f147";
}
.fi-rs-clip:before {
    content: "\f148";
}
.fi-rs-clock:before {
    content: "\f149";
}
.fi-rs-cloud-check:before {
    content: "\f14a";
}
.fi-rs-cloud-disabled:before {
    content: "\f14b";
}
.fi-rs-cloud-download:before {
    content: "\f14c";
}
.fi-rs-cloud-share:before {
    content: "\f14d";
}
.fi-rs-cloud-upload:before {
    content: "\f14e";
}
.fi-rs-cloud:before {
    content: "\f14f";
}
.fi-rs-comment-alt:before {
    content: "\f150";
}
.fi-rs-comment:before {
    content: "\f151";
}
.fi-rs-compress-alt:before {
    content: "\f152";
}
.fi-rs-compress:before {
    content: "\f153";
}
.fi-rs-computer:before {
    content: "\f154";
}
.fi-rs-cookie:before {
    content: "\f155";
}
.fi-rs-copy-alt:before {
    content: "\f156";
}
.fi-rs-copy:before {
    content: "\f157";
}
.fi-rs-copyright:before {
    content: "\f158";
}
.fi-rs-cream:before {
    content: "\f159";
}
.fi-rs-credit-card:before {
    content: "\f15a";
}
.fi-rs-cross-circle:before {
    content: "\f15b";
}
.fi-rs-cross-small:before {
    content: "\f15c";
}
.fi-rs-cross:before {
    content: "\f15d";
}
.fi-rs-crown:before {
    content: "\f15e";
}
.fi-rs-cursor-finger:before {
    content: "\f15f";
}
.fi-rs-cursor-plus:before {
    content: "\f160";
}
.fi-rs-cursor-text-alt:before {
    content: "\f161";
}
.fi-rs-cursor-text:before {
    content: "\f162";
}
.fi-rs-cursor:before {
    content: "\f163";
}
.fi-rs-dart:before {
    content: "\f164";
}
.fi-rs-dashboard:before {
    content: "\f165";
}
.fi-rs-data-transfer:before {
    content: "\f166";
}
.fi-rs-database:before {
    content: "\f167";
}
.fi-rs-delete:before {
    content: "\f168";
}
.fi-rs-diamond:before {
    content: "\f169";
}
.fi-rs-dice:before {
    content: "\f16a";
}
.fi-rs-diploma:before {
    content: "\f16b";
}
.fi-rs-disk:before {
    content: "\f16c";
}
.fi-rs-doctor:before {
    content: "\f16d";
}
.fi-rs-document-signed:before {
    content: "\f16e";
}
.fi-rs-document:before {
    content: "\f16f";
}
.fi-rs-dollar:before {
    content: "\f170";
}
.fi-rs-download:before {
    content: "\f171";
}
.fi-rs-duplicate:before {
    content: "\f172";
}
.fi-rs-e-learning:before {
    content: "\f173";
}
.fi-rs-earnings:before {
    content: "\f174";
}
.fi-rs-edit-alt:before {
    content: "\f175";
}
.fi-rs-edit:before {
    content: "\f176";
}
.fi-rs-envelope:before {
    content: "\f177";
}
.fi-rs-euro:before {
    content: "\f178";
}
.fi-rs-exclamation:before {
    content: "\f179";
}
.fi-rs-expand:before {
    content: "\f17a";
}
.fi-rs-eye-crossed:before {
    content: "\f17b";
}
.fi-rs-eye-dropper:before {
    content: "\f17c";
}
.fi-rs-eye:before {
    content: "\f17d";
}
.fi-rs-feather:before {
    content: "\f17e";
}
.fi-rs-file-add:before {
    content: "\f17f";
}
.fi-rs-file-ai:before {
    content: "\f180";
}
.fi-rs-file-check:before {
    content: "\f181";
}
.fi-rs-file-delete:before {
    content: "\f182";
}
.fi-rs-file-eps:before {
    content: "\f183";
}
.fi-rs-file-gif:before {
    content: "\f184";
}
.fi-rs-file-music:before {
    content: "\f185";
}
.fi-rs-file-psd:before {
    content: "\f186";
}
.fi-rs-file:before {
    content: "\f187";
}
.fi-rs-fill:before {
    content: "\f188";
}
.fi-rs-film:before {
    content: "\f189";
}
.fi-rs-filter:before {
    content: "\f18a";
}
.fi-rs-fingerprint:before {
    content: "\f18b";
}
.fi-rs-flag:before {
    content: "\f18c";
}
.fi-rs-flame:before {
    content: "\f18d";
}
.fi-rs-flip-horizontal:before {
    content: "\f18e";
}
.fi-rs-folder-add:before {
    content: "\f18f";
}
.fi-rs-folder:before {
    content: "\f190";
}
.fi-rs-following:before {
    content: "\f191";
}
.fi-rs-football:before {
    content: "\f192";
}
.fi-rs-form:before {
    content: "\f193";
}
.fi-rs-forward:before {
    content: "\f194";
}
.fi-rs-ftp:before {
    content: "\f195";
}
.fi-rs-gallery:before {
    content: "\f196";
}
.fi-rs-glasses:before {
    content: "\f197";
}
.fi-rs-globe-alt:before {
    content: "\f198";
}
.fi-rs-globe:before {
    content: "\f199";
}
.fi-rs-golf:before {
    content: "\f19a";
}
.fi-rs-graduation-cap:before {
    content: "\f19b";
}
.fi-rs-graphic-tablet:before {
    content: "\f19c";
}
.fi-rs-grid-alt:before {
    content: "\f19d";
}
.fi-rs-grid:before {
    content: "\f19e";
}
.fi-rs-gym:before {
    content: "\f19f";
}
.fi-rs-headphones:before {
    content: "\f1a0";
}
.fi-rs-headset:before {
    content: "\f1a1";
}
.fi-rs-heart:before {
    content: "\f1a2";
}
.fi-rs-home:before {
    content: "\f1a3";
}
.fi-rs-hourglass-end:before {
    content: "\f1a4";
}
.fi-rs-hourglass:before {
    content: "\f1a5";
}
.fi-rs-ice-skate:before {
    content: "\f1a6";
}
.fi-rs-id-badge:before {
    content: "\f1a7";
}
.fi-rs-inbox:before {
    content: "\f1a8";
}
.fi-rs-incognito:before {
    content: "\f1a9";
}
.fi-rs-indent:before {
    content: "\f1aa";
}
.fi-rs-infinity:before {
    content: "\f1ab";
}
.fi-rs-info:before {
    content: "\f1ac";
}
.fi-rs-interactive:before {
    content: "\f1ad";
}
.fi-rs-interlining:before {
    content: "\f1ae";
}
.fi-rs-interrogation:before {
    content: "\f1af";
}
.fi-rs-italic:before {
    content: "\f1b0";
}
.fi-rs-jpg:before {
    content: "\f1b1";
}
.fi-rs-key:before {
    content: "\f1b2";
}
.fi-rs-keyboard:before {
    content: "\f1b3";
}
.fi-rs-label:before {
    content: "\f1b4";
}
.fi-rs-laptop:before {
    content: "\f1b5";
}
.fi-rs-lasso:before {
    content: "\f1b6";
}
.fi-rs-layers:before {
    content: "\f1b7";
}
.fi-rs-layout-fluid:before {
    content: "\f1b8";
}
.fi-rs-letter-case:before {
    content: "\f1b9";
}
.fi-rs-life-ring:before {
    content: "\f1ba";
}
.fi-rs-line-width:before {
    content: "\f1bb";
}
.fi-rs-link:before {
    content: "\f1bc";
}
.fi-rs-lipstick:before {
    content: "\f1bd";
}
.fi-rs-list-check:before {
    content: "\f1be";
}
.fi-rs-list:before {
    content: "\f1bf";
}
.fi-rs-location-alt:before {
    content: "\f1c0";
}
.fi-rs-lock-alt:before {
    content: "\f1c1";
}
.fi-rs-lock:before {
    content: "\f1c2";
}
.fi-rs-magic-wand:before {
    content: "\f1c3";
}
.fi-rs-makeup-brush:before {
    content: "\f1c4";
}
.fi-rs-marker-time:before {
    content: "\f1c5";
}
.fi-rs-marker:before {
    content: "\f1c6";
}
.fi-rs-medicine:before {
    content: "\f1c7";
}
.fi-rs-megaphone:before {
    content: "\f1c8";
}
.fi-rs-menu-burger:before {
    content: "\f1c9";
}
.fi-rs-menu-dots-vertical:before {
    content: "\f1ca";
}
.fi-rs-menu-dots:before {
    content: "\f1cb";
}
.fi-rs-microphone:before {
    content: "\f1cc";
}
.fi-rs-minus-small:before {
    content: "\f1cd";
}
.fi-rs-minus:before {
    content: "\f1ce";
}
.fi-rs-mobile:before {
    content: "\f1cf";
}
.fi-rs-mode-landscape:before {
    content: "\f1d0";
}
.fi-rs-mode-portrait:before {
    content: "\f1d1";
}
.fi-rs-money:before {
    content: "\f1d2";
}
.fi-rs-mouse:before {
    content: "\f1d3";
}
.fi-rs-music-alt:before {
    content: "\f1d4";
}
.fi-rs-music:before {
    content: "\f1d5";
}
.fi-rs-network-cloud:before {
    content: "\f1d6";
}
.fi-rs-network:before {
    content: "\f1d7";
}
.fi-rs-notebook:before {
    content: "\f1d8";
}
.fi-rs-opacity:before {
    content: "\f1d9";
}
.fi-rs-package:before {
    content: "\f1da";
}
.fi-rs-paint-brush:before {
    content: "\f1db";
}
.fi-rs-palette:before {
    content: "\f1dc";
}
.fi-rs-paper-plane:before {
    content: "\f1dd";
}
.fi-rs-password:before {
    content: "\f1de";
}
.fi-rs-pause:before {
    content: "\f1df";
}
.fi-rs-pencil:before {
    content: "\f1e0";
}
.fi-rs-pharmacy:before {
    content: "\f1e1";
}
.fi-rs-physics:before {
    content: "\f1e2";
}
.fi-rs-picture:before {
    content: "\f1e3";
}
.fi-rs-ping-pong:before {
    content: "\f1e4";
}
.fi-rs-play-alt:before {
    content: "\f1e5";
}
.fi-rs-play:before {
    content: "\f1e6";
}
.fi-rs-playing-cards:before {
    content: "\f1e7";
}
.fi-rs-plus-small:before {
    content: "\f1e8";
}
.fi-rs-plus:before {
    content: "\f1e9";
}
.fi-rs-poker-chip:before {
    content: "\f1ea";
}
.fi-rs-portrait:before {
    content: "\f1eb";
}
.fi-rs-pound:before {
    content: "\f1ec";
}
.fi-rs-power:before {
    content: "\f1ed";
}
.fi-rs-presentation:before {
    content: "\f1ee";
}
.fi-rs-print:before {
    content: "\f1ef";
}
.fi-rs-protractor:before {
    content: "\f1f0";
}
.fi-rs-pulse:before {
    content: "\f1f1";
}
.fi-rs-quote-right:before {
    content: "\f1f2";
}
.fi-rs-rec:before {
    content: "\f1f3";
}
.fi-rs-receipt:before {
    content: "\f1f4";
}
.fi-rs-rectangle-horizontal:before {
    content: "\f1f5";
}
.fi-rs-rectangle-panoramic:before {
    content: "\f1f6";
}
.fi-rs-rectangle-vertical:before {
    content: "\f1f7";
}
.fi-rs-redo:before {
    content: "\f1f8";
}
.fi-rs-reflect:before {
    content: "\f1f9";
}
.fi-rs-refresh:before {
    content: "\f1fa";
}
.fi-rs-resize:before {
    content: "\f1fb";
}
.fi-rs-resources:before {
    content: "\f1fc";
}
.fi-rs-rewind:before {
    content: "\f1fd";
}
.fi-rs-rocket:before {
    content: "\f1fe";
}
.fi-rs-rotate-right:before {
    content: "\f1ff";
}
.fi-rs-rugby:before {
    content: "\f200";
}
.fi-rs-scale:before {
    content: "\f201";
}
.fi-rs-school-bus:before {
    content: "\f202";
}
.fi-rs-school:before {
    content: "\f203";
}
.fi-rs-scissors:before {
    content: "\f204";
}
.fi-rs-screen:before {
    content: "\f205";
}
.fi-rs-search-alt:before {
    content: "\f206";
}
.fi-rs-search:before {
    content: "\f207";
}
.fi-rs-settings-sliders:before {
    content: "\f208";
}
.fi-rs-settings:before {
    content: "\f209";
}
.fi-rs-share:before {
    content: "\f20a";
}
.fi-rs-shield-check:before {
    content: "\f20b";
}
.fi-rs-shield-exclamation:before {
    content: "\f20c";
}
.fi-rs-shield-interrogation:before {
    content: "\f20d";
}
.fi-rs-shield-plus:before {
    content: "\f20e";
}
.fi-rs-shield:before {
    content: "\f20f";
}
.fi-rs-shop:before {
    content: "\f210";
}
.fi-rs-shopping-bag-add:before {
    content: "\f211";
}
.fi-rs-shopping-bag:before {
    content: "\f212";
}
.fi-rs-shopping-cart-add:before {
    content: "\f213";
}
.fi-rs-shopping-cart-check:before {
    content: "\f214";
}
.fi-rs-shopping-cart:before {
    content: "\f215";
}
.fi-rs-shuffle:before {
    content: "\f216";
}
.fi-rs-sign-in:before {
    content: "\f217";
}
.fi-rs-sign-out:before {
    content: "\f218";
}
.fi-rs-signal-alt-1:before {
    content: "\f219";
}
.fi-rs-signal-alt-2:before {
    content: "\f21a";
}
.fi-rs-signal-alt:before {
    content: "\f21b";
}
.fi-rs-skateboard:before {
    content: "\f21c";
}
.fi-rs-smartphone:before {
    content: "\f21d";
}
.fi-rs-soap:before {
    content: "\f21e";
}
.fi-rs-spa:before {
    content: "\f21f";
}
.fi-rs-speaker:before {
    content: "\f220";
}
.fi-rs-spinner-alt:before {
    content: "\f221";
}
.fi-rs-spinner:before {
    content: "\f222";
}
.fi-rs-square-root:before {
    content: "\f223";
}
.fi-rs-square:before {
    content: "\f224";
}
.fi-rs-star:before {
    content: "\f225";
}
.fi-rs-stats:before {
    content: "\f226";
}
.fi-rs-stethoscope:before {
    content: "\f227";
}
.fi-rs-sticker:before {
    content: "\f228";
}
.fi-rs-stop:before {
    content: "\f229";
}
.fi-rs-stopwatch:before {
    content: "\f22a";
}
.fi-rs-subtitles:before {
    content: "\f22b";
}
.fi-rs-surfing:before {
    content: "\f22c";
}
.fi-rs-sword:before {
    content: "\f22d";
}
.fi-rs-syringe:before {
    content: "\f22e";
}
.fi-rs-tablet:before {
    content: "\f22f";
}
.fi-rs-target:before {
    content: "\f230";
}
.fi-rs-tennis:before {
    content: "\f231";
}
.fi-rs-test-tube:before {
    content: "\f232";
}
.fi-rs-test:before {
    content: "\f233";
}
.fi-rs-text-check:before {
    content: "\f234";
}
.fi-rs-text:before {
    content: "\f235";
}
.fi-rs-thumbs-down:before {
    content: "\f236";
}
.fi-rs-thumbs-up:before {
    content: "\f237";
}
.fi-rs-ticket:before {
    content: "\f238";
}
.fi-rs-time-add:before {
    content: "\f239";
}
.fi-rs-time-check:before {
    content: "\f23a";
}
.fi-rs-time-delete:before {
    content: "\f23b";
}
.fi-rs-time-fast:before {
    content: "\f23c";
}
.fi-rs-time-forward-sixty:before {
    content: "\f23d";
}
.fi-rs-time-forward-ten:before {
    content: "\f23e";
}
.fi-rs-time-forward:before {
    content: "\f23f";
}
.fi-rs-time-half-past:before {
    content: "\f240";
}
.fi-rs-time-oclock:before {
    content: "\f241";
}
.fi-rs-time-past:before {
    content: "\f242";
}
.fi-rs-time-quarter-past:before {
    content: "\f243";
}
.fi-rs-time-quarter-to:before {
    content: "\f244";
}
.fi-rs-time-twenty-four:before {
    content: "\f245";
}
.fi-rs-tool-crop:before {
    content: "\f246";
}
.fi-rs-tool-marquee:before {
    content: "\f247";
}
.fi-rs-tooth:before {
    content: "\f248";
}
.fi-rs-transform:before {
    content: "\f249";
}
.fi-rs-trash:before {
    content: "\f24a";
}
.fi-rs-treatment:before {
    content: "\f24b";
}
.fi-rs-trophy:before {
    content: "\f24c";
}
.fi-rs-umbrella:before {
    content: "\f24d";
}
.fi-rs-underline:before {
    content: "\f24e";
}
.fi-rs-undo:before {
    content: "\f24f";
}
.fi-rs-unlock:before {
    content: "\f250";
}
.fi-rs-upload:before {
    content: "\f251";
}
.fi-rs-usb-drive:before {
    content: "\f252";
}
.fi-rs-user-add:before {
    content: "\f253";
}
.fi-rs-user-delete:before {
    content: "\f254";
}
.fi-rs-user-remove:before {
    content: "\f255";
}
.fi-rs-user-time:before {
    content: "\f256";
}
.fi-rs-user:before {
    content: "\f257";
}
.fi-rs-users:before {
    content: "\f258";
}
.fi-rs-vector-2:before {
    content: "\f259";
}
.fi-rs-vector-alt:before {
    content: "\f25a";
}
.fi-rs-video-camera:before {
    content: "\f25b";
}
.fi-rs-volleyball:before {
    content: "\f25c";
}
.fi-rs-volume:before {
    content: "\f25d";
}
.fi-rs-wifi-alt:before {
    content: "\f25e";
}
.fi-rs-world:before {
    content: "\f25f";
}
.fi-rs-yen:before {
    content: "\f260";
}
.fi-rs-zoom-in:before {
    content: "\f261";
}
.fi-rs-zoom-out:before {
    content: "\f262";
}
